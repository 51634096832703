@-ms-viewport{
  width: device-width;
}

* {
  font-family: 'Nunito', sans-serif;
}

html, body, #root {
    width: 100vw;
    height: 100vh;
}

a {
  transition: color 0.5s ease;
}

a:hover {
  color: #00d663 !important;
}

.App {
  text-align: center;
}

.hideSymbol {
    display: none !important;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}