.text-transition-inner {
  display: flex !important;
  /*margin-left: 20px;*/
  color: #00d663;
}

.google-login {
  font-family: 'Roboto, sans-serif';
  margin: 5px;
}
.google-login div:first-child{
  display: flex;
}

.kep-login-facebook {
  display: flex !important;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px !important;
  margin: 5px;
  height: 42px;
  padding: 5px 10px !important;
  text-transform: initial !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}