body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background: transparent;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  outline: 1px solid slategrey;
}

.blink-1 {
  -webkit-animation: blink-1 5s ease-in infinite both;
  animation: blink-1 5s ease-in infinite both;
}
/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

/** MATERIAL **/
.mdc-text-red {
  color: #f44336 !important;
}

.mdc-menu-device .mdc-list {
  font-size: 0.8rem;
}

.noscroll {
  overflow-x: hidden !important;
}

.reex-theme {
  padding: 0 !important;
}

.reex-theme > .arrow-regular {
  border-top-color: #0fd691 !important;
}
