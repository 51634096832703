body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  background: transparent;
}

::-webkit-scrollbar {
  width: 0.25em;
  height: 0.25em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  outline: 1px solid slategrey;
}

.blink-1 {
  -webkit-animation: blink-1 5s ease-in infinite both;
  animation: blink-1 5s ease-in infinite both;
}
/**
 * ----------------------------------------
 * animation blink-1
 * ----------------------------------------
 */
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

/** MATERIAL **/
.mdc-text-red {
  color: #f44336 !important;
}

.mdc-menu-device .mdc-list {
  font-size: 0.8rem;
}

.noscroll {
  overflow-x: hidden !important;
}

.reex-theme {
  padding: 0 !important;
}

.reex-theme > .arrow-regular {
  border-top-color: #0fd691 !important;
}

@-ms-viewport{
  width: device-width;
}

* {
  font-family: 'Nunito', sans-serif;
}

html, body, #root {
    width: 100vw;
    height: 100vh;
}

a {
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
}

a:hover {
  color: #00d663 !important;
}

.App {
  text-align: center;
}

.hideSymbol {
    display: none !important;
}

[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}
.text-transition-inner {
  display: flex !important;
  /*margin-left: 20px;*/
  color: #00d663;
}

.google-login {
  font-family: 'Roboto, sans-serif';
  margin: 5px;
}
.google-login div:first-child{
  display: flex;
}

.kep-login-facebook {
  display: flex !important;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
  border-radius: 2px !important;
  margin: 5px;
  height: 42px;
  padding: 5px 10px !important;
  text-transform: none !important;
  text-transform: initial !important;
  font-weight: 400 !important;
  font-size: 14px !important;
}
#admin ::-webkit-input-placeholder {
    color: rgba(0,0,0,.5);
}

